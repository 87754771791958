import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import * as styles from "./index.module.scss";

const PageLayout = ({ title, children }) => {
  return (
    <div className={title === "Features" ? styles.featuresPageLayout : styles.pageLayout}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

PageLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default PageLayout;
